<template>
  <v-container>
    <p class="text-h5"> LOG Table</p>
  <v-simple-table 
    dense
    fixed-header
    height="600px"
  >
    <template v-slot:default>
       
      <thead>
        <tr>
          <th class="text-center">
            id
          </th>
          <th class="text-center">
            Date
          </th>
          <th class="text-center">
            Time
          </th>
          <th class="text-center">
            Pump ID
          </th>
          <th class="text-center">
            Nozzle ID
          </th>
          <th class="text-center">
            Type ID
          </th>
          <th class="text-center">
            Message
          </th>
           <th class="text-center">
            Value
          </th>
           <th class="text-center">
            Device
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in logs"
          :key="item.name"
        >
          <td class="text-center">{{ item.id }}</td>
          <td class="text-center">{{ new Date(item.time_stamp).toLocaleDateString() }}</td>
          <td class="text-center">{{ new Date(item.time_stamp).toLocaleTimeString() }}</td>
          <td class="text-center">{{ item.pump_id }}</td>
          <td class="text-center">{{ item.nozzle_id }}</td>
          <td class="text-center">{{ item.type_id }}</td>
          <td class="text-center">{{ item.log_text }}</td>
          <td class="text-center">{{ item.log_value }}</td>
          <td class="text-center">{{ item.device }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
  </v-container>
</template>

<script>
export default {
    name: "Logs",

    data () { 
      return {
        logs: [],
        loading: false,
      }
    },

    methods: {
      async initialize () {
        try {
          this.loading = true
          const res = await this.$http.get("/log")
          this.logs = res.data
          
        } catch (e) {
          console.log(e)
          this.loading = false
        } finally {
          this.loading = false
        }
      }
    },

    created () {
      this.initialize()
    }

}
</script>

<style>

</style>