<template>
  <v-card flat>
    <v-container>
        <v-row justify="center">
          <p class="text-h5"> {{$t('watchdog')}}</p>
        </v-row>
        <v-row justify="center" class="pt-4">
          <v-col cols="12" md="4">
            <v-switch
              v-model="watchdog.allow"
              :label="$t('enable_watchdog')"
              color="#196619"
              :true-value="1"
              :false-value="0"
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
        <div v-show="watchdog.allow">
        <v-row  justify="center">
          <v-col cols="12" md="4">
            <v-text-field
              outlined
              dense
              :label="$t('Timeout')"
              v-model.number="watchdog.timeout"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">  
          <v-col cols="12" md="4">
            <v-text-field
              outlined
              dense
              :label="$t('ping_address')"
              v-model="watchdog.ip"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-text-field
              outlined
              dense
              :label="$t('errors_ping')"
              v-model.number="watchdog.errors"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4">
            <v-text-field
              outlined
              dense
              :label="$t('reboot_time')"
              v-model="watchdog.time"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4" >
            <v-switch
              v-model="watchdog.probes"
              :label="$t('allow_watchdog')"
              color="#196619"
              :true-value="1"
              :false-value="0"
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" md="4"  >
            <v-switch
              v-model="watchdog.force"
              :label="$t('force_reboot')"
              color="#196619"
              :true-value="1"
              :false-value="0"
              hide-details
            ></v-switch>
          </v-col>
        </v-row>
      </div>
        <v-row justify="center">
          <v-col cols="12" md="4" align="right">
            <v-btn
              color="#196619"
              dark
              min-width="150"
              :loading="loading"
              @click="save"
              >{{ $t("save") }}</v-btn
            >
          </v-col>
        </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "Watchdog",
  data() {
    return {
      watchdog: {
        allow: false,
        ip:"",
        timeout: 0,
        probes: 0,

      },
      loading: false,
      keyboard: false,
      singleRegime: false,
    };
  },
  methods: {
    async initialize () {
        let res = null
        try { 
          res = await this.$http.get("/watchdog")
          this.watchdog = res.data
        } catch (e) {
          console.log(e)
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
        } 
    },
    proceedAction(act) {
      this.action = act;
      this.dialog = true;
    },
    async save() {
      let res = null;
      try {
        this.loading = true;
        res = await this.$http.put(`/watchdog`, this.watchdog);
        this.$emit("alertMsg", {
          type: "success",
          text: "Data saved OK",
        });
      } catch (e) {
        console.log(e);
        this.$emit("alertMsg", {
          type: "error",
          text: e.message + "\n" + res?.error,
        });
        this.loading = false;
      } finally {
        this.loading = false;
        // this.$store.commit('SET_STATION',res.data)
      }
    },
  },
  computed: {
   
  },
  created () {
      this.initialize()
    },
};
</script>
